import React from 'react';

import hexImg from '../../assets/careers/Requirements/hexImg.png';
import hexShadow from '../../assets/careers/Requirements/hexShadow.png';
import lines from '../../assets/careers/Requirements/lines.png';
import Bulb from '../../assets/careers/SvgComponents/Bulb';
import MsgPop from '../../assets/careers/SvgComponents/MsgPop';

import * as styles from './index.module.scss';

const JOB_POST_URL = 'https://www.linkedin.com/jobs/view/4086412327/';

function Requirements() {

  const buttonProps = JOB_POST_URL ? {
    href: JOB_POST_URL,
    target: '_blank',
    rel: 'noreferrer noopener',
  } : {
    'data-tf-popup': 'pAZxKhLO'
  }

  return (
    <section className={styles.requirements} id="requirements">
      <div className={styles.imagesWrapper}>
        <img src={hexImg} alt="Person image" className={styles.hexagonImg} />
        <img src={hexShadow} alt="shadow" className={styles.hexagonShadow} />
        <img src={lines} alt="Lines texture" className={styles.triangleLines} />
        <Bulb className={styles.bulbIcon} />
        <MsgPop className={styles.msgPopIcon} />
      </div>
      <div className={styles.backgroundImage}>
        <div className={styles.textContainer}>
          <article className={styles.mainParagraph}>
            <h2 className={styles.title}>
              Requirements &amp; Responsibilities
            </h2>
            <p className={styles.leadingParagraph}>
              Our clients value our expertise and we often lead projects,
              dictate tech related decisions and help their teams grow and
              succeed.&nbsp;
              <strong>
                Your voice matters, and influences not just your own code but
                the products you’ll build and the people you interact with.
              </strong>
              {` `}You should:
            </p>
            <ul>
              <li>
                <p className={styles.listText}>
                  Have <strong>demonstrated</strong> experience doing Front End
                  development professionally, including creating and maintaining
                  significant applications in production.
                </p>
              </li>
              <li>
                <p className={styles.listText}>
                  Be nice, professional, resourceful and both willing and
                  capable to <strong>learn fast, teach and improve</strong>.
                </p>
              </li>
              <li>
                <p className={styles.listText}>
                  Have <strong>excellent</strong> written and verbal Hebrew
                  &amp; English.
                </p>
              </li>
            </ul>
          </article>
          <article className={styles.concludeText}>
            <h3>
              If you care about what you build and how you do it, this may be a
              great fit!
            </h3>
            <a
              className={styles.button}
              {...buttonProps}
              data-tf-size="75"
              data-tf-iframe-props="title=The Really Good Job Application Form"
            >
              Apply Now
            </a>
          </article>
        </div>
      </div>
    </section>
  );
}

export default Requirements;
